<template>
  <div class="myPage">
    <el-container class="normalCon">
      <el-main>
        <i class="el-icon-arrow-left topLogo" @click="back()"></i>
        <div class="listTable">
          <div class="tableSwiper">
            <img src="../assets/banner1.jpg" alt="">
          </div>
          <div class="introMain">
            <h5>公司介绍</h5>
            <p>佛山市灏海餐饮管理有限公司始终以诚实守信，文明优质，精品一流的服务宗旨，以诚挚的爱心，规范的管理，真诚的服务</p>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    // 返回上一页
    back() {
      this.$router.replace("Home");
    },
  },
}
</script>

<style scoped lang="scss">
.listTable {
  position: relative;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  .tableSwiper {
    position: relative;
    max-height: 35vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .introMain {
    position: relative;
    padding: 15px 15px 0;
    h5 {
      font-size: 1.6rem;
      padding: 10px 0 15px;
      text-align: center;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.8;
      text-indent: 2em;
      margin: 0 0 10px;
    }
  }
}

</style>
